import config from "@/config/env";
const baseUrl = config.api;
/* 富文本编辑图片上传配置*/
const uploadConfig = {
  action: baseUrl + "/file/upload",
  methods: "POST", // 必填参数 图片上传方式
  token: sessionStorage.getItem("token"), // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
  name: "file", // 必填参数 文件的参数名
  // size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon", // 可选 可上传的图片格式
  // type:'audio/mp4,video/mp4'
};

// toolbar工具栏的工具选项（默认展示全部）
// const toolOptions = [
// ["bold", "italic", "underline", "strike"],
// ["blockquote", "code-block"],
// [{ header: 1 }, { header: 2 }],
// [{ list: "ordered" }, { list: "bullet" }],
// [{ script: "sub" }, { script: "super" }],
// [{ indent: "-1" }, { indent: "+1" }],
// [{ direction: "rtl" }],
// [{ size: ["small", false, "large", "huge"] }],
// [{ header: [1, 2, 3, 4, 5, 6, false] }],
// [{ color: [] }, { background: [] }],
// [{ font: [] }],
// [{ align: [] }],
// "link",
// [ "image", "video"],
// ["clean"],
// ];
const toolOptions = [
  ["bold", "italic"],
  // ["blockquote", "code-block"],
  // [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  // [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }],
  // [{ font: [] }],
  [{ align: [] }],
  ["link"],
  ["image", "video"],
];
const handlers = {
  image: function image() {
    var self = this;
    var fileInput = this.container.querySelector("input.ql-image[type=file]");
    if (fileInput === null) {
      fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      // 设置图片参数名
      if (uploadConfig.name) {
        fileInput.setAttribute("name", uploadConfig.name);
      }
      // 可设置上传图片的格式
      fileInput.setAttribute("accept", uploadConfig.accept);
      fileInput.classList.add("ql-image");
      // 监听选择文件
      fileInput.addEventListener("change", async function() {
        // 创建formData
        var formData = new FormData();
        formData.append(uploadConfig.name, fileInput.files[0]);
        formData.append("type", "IMAGE");
        // 图片上传
        var xhr = new XMLHttpRequest();
        xhr.open(uploadConfig.methods, uploadConfig.action, true);
        xhr.setRequestHeader("Authorization", uploadConfig.token);
        // 上传数据成功，会触发
        xhr.onload = function(e) {
          if (xhr.status === 200) {
            var res = JSON.parse(xhr.responseText);
            const length = self.quill.getSelection(true).index;
            // 这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。
            self.quill.insertEmbed(length, "image", res.data.url);
            self.quill.setSelection(length + 1);
          }
          fileInput.value = "";
        };
        // 开始上传数据
        xhr.upload.onloadstart = function(e) {
          fileInput.value = "";
        };
        // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
        xhr.upload.onerror = function(e) {};
        // 上传数据完成（成功或者失败）时会触发
        xhr.upload.onloadend = function(e) {
          // console.log('上传结束')
        };
        xhr.send(formData);
      });
      this.container.appendChild(fileInput);
    }
    fileInput.click();
  },
  video: function(value) {
    var self = this;
    var fileInput = this.container.querySelector("input.ql-video[type=file]");
    if (fileInput === null) {
      fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      if (uploadConfig.name) {
        fileInput.setAttribute("name", uploadConfig.name);
      }
      fileInput.setAttribute("accept", "video/mp4");
      fileInput.classList.add("ql-video");
      fileInput.addEventListener("change", function() {
        var formData = new FormData();
        formData.append(uploadConfig.name, fileInput.files[0]);
        formData.append("type", "VIDEO");
        if (uploadConfig.token) {
          formData.append("Authorization", uploadConfig.token);
        }

        var xhr = new XMLHttpRequest();
        xhr.open(uploadConfig.methods, uploadConfig.action, true);
        xhr.setRequestHeader("Authorization", uploadConfig.token);
        // 上传数据成功，会触发
        xhr.onload = function(e) {
          if (xhr.status === 200) {
            var res = JSON.parse(xhr.responseText);
            const length = self.quill.getSelection(true).index;
            //
            self.quill.insertEmbed(length, "video", res.data.url);
            self.quill.setSelection(length + 1);
          }
          // fileInput.value = "";
        };
        // 开始上传数据
        xhr.upload.onloadstart = function(e) {
          fileInput.value = "";
        };
        // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
        xhr.upload.onerror = function(e) {};
        // 上传数据完成（成功或者失败）时会触发
        xhr.upload.onloadend = function(e) {
          // console.log('上传结束')
        };
        xhr.send(formData);
        // fileUpload(formData).then(response => {
        //   console.log('upload response:', response)
        //   let url = 'https://www.lumingtec.cn/Business/file/' + response.fileName
        //   let length = self.quill.getSelection().index　//获取当前鼠标焦点位置
        //   self.quill.insertEmbed(length, 'video',url)
        // })
      });
      // this.container.appendChild(fileInput)
    }
    fileInput.click();

    // if (value) {
    //   // 触发input框选择视频文件
    //   document.querySelector(".avatar-uploader-editor-video input").click();
    // } else {
    //   this.quill.format("video", false);
    // }
  },
};
function handleCustomMatcher(node, Delta) {
  if (node.src && node.src.indexOf("data:image/png") > -1) {
    Delta.ops = [];
    return Delta;
  }
  let ops = [];
  Delta.ops.forEach((op) => {
    if (op.insert && typeof op.insert === "string") {
      ops.push({
        insert: op.insert,
      });
    } else if (op.insert && typeof op.insert.image === "string") {
      ops.push({
        insert: op.insert,
      });
    } else if (op.insert && typeof op.insert === "object") {
      ops.push({
        insert: op.insert,
      });
    }
  });
  Delta.ops = ops;
  return Delta;
}
export default {
  placeholder: "",
  theme: "snow", // 主题
  modules: {
    clipboard: {
      // 粘贴版，处理粘贴时候带图片
      matchers: [[Node.ELEMENT_NODE, handleCustomMatcher]],
    },
    toolbar: {
      container: toolOptions, // 工具栏选项
      handlers: handlers, // 事件重写
    },
    //添加的
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  },
};
