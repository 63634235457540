<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="标题" prop="title" :rules="rules.blurRule">
        <el-input
          placeholder="请输入内容"
          v-model="ruleForm.title"
        ></el-input>
      </el-form-item>
      <el-form-item label="封面图" prop="coverUrl" :rules="rules.typeIcon">
        <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.coverUrl}]"></uploadImg>
      </el-form-item>
      <el-form-item label="所属专题" prop="topicId" :rules="rules.changeRule">
        <el-select v-model="ruleForm.topicId" placeholder="请选择所属专题">
          <el-option
            v-for="item in subjectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="作者id" prop="authorId" :rules="rules.blurRule">
        <el-input
          placeholder="请输入内容"
          v-model="ruleForm.authorId"
        ></el-input>
      </el-form-item>
      <el-form-item label="房间id">
        <el-input
          placeholder="请输入内容"
          v-model="ruleForm.roomId"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content" :rules="rules.blurRule">
          <quillEditor :content="ruleForm.content" @change="editorDetailFunc"></quillEditor>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="cancelFnc">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import quillEditor from "@/components/quillEditor";
import uploadImg from '@/components/uploadImg'
export default {
  data() {
    return {
      ruleForm: {},
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      subjectOptions:[],
      edit:{}
    };
  },
  components:{uploadImg,quillEditor},
  created() {
    const editForm = this.$route.params
     if(editForm?.id) {
       this.ruleForm = {...editForm}
       this.edit = {...editForm}
     } else {
      this.ruleForm = {};
     }
     this.getTopicList();
  },
  methods: {
    async getTopicList() {
      let res = await this.$http.topicList({pageNo:1,pageSize:100});
      if (res) {
        this.subjectOptions = res.result.data;
      }
    },
    editorDetailFunc(data,txt) {
      this.ruleForm.content = data;
      this.ruleForm.simpleContent=txt.replace(/\s+/g, ' ').slice(0, 300)+'...';
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.coverUrl = val[0].url
      }
    },
    async submitFunc() {
      console.log(this.ruleForm)
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          
          let res = await this.$http.articleCreate({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.$refs.ruleForm.resetFields();
            this.cancelFnc();
          }
        }
      });
    },
    cancelFnc(){
      this.$router.push({name:'articleList'})
    }
  },
};
</script>

<style scoped>

</style>
